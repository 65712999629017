<template>
  <div class="new-header">
    <div
      v-if="isLoading"
      class="mt-4 text-center">
      <v-progress-circular
        indeterminate
        color="secondary"/>
    </div>

    <template v-else>
      <v-row v-if="!logProvider">
        <v-col
          cols="8"
          offset="2">
          <v-card class="mt-4 pa-4 text-center">
            <h3>
              {{ $t('noConfigTitle') }}
            </h3>

            <section v-has-rights-to="['UpdateProject', projectCanonical]">
              <p>{{ $t('noConfigDesc') }}</p>

              <CyButton
                :to="{
                  name: 'projectConfigurationLogs',
                  // envCanonical is necessary here, as projectConfigurationLogs
                  // does not use envCanonical in its $route.params, so we pass
                  // it manually, in order to get back to this env route safely
                  params: { envCanonical, backRouteTo: $route.name },
                }"
                variant="secondary">
                {{ $t('btnConfig') }}
              </CyButton>
            </section>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        v-else
        v-has-rights-to="['GetProjEnvLogStreams', projectCanonical]"
        no-gutters>
        <v-col
          v-if="!isFullScreen"
          cols="3"
          class="source-selection">
          <div>
            <v-list
              v-if="hasEnvSources"
              class="pa-0"
              nav>
              <v-subheader>
                {{ $t('sources') }}
              </v-subheader>
              <v-list-item
                v-for="({ id: sourceId, idx }) of logSources"
                :key="idx"
                :class="['px-0 mb-1 ml-n3', {
                  'v-list-item--active': selection.source === sourceId,
                }]"
                @click="selectSource(sourceId)">
                <v-list-item-content>
                  <v-list-item-title>
                    <span
                      class="source-title"
                      v-html="$sanitizeHtml(sourceId)"/>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <div
              v-else
              class="grey--text text--darken-1 font-16">
              {{ $t('noSource') }}
            </div>
          </div>
        </v-col>

        <v-col
          :cols="isFullScreen ? 12 : 9"
          :class="{ 'pl-6': !isFullScreen }">
          <div v-if="hasSelection && !isFullScreen">
            <v-row no-gutters>
              <v-col
                v-show="isAdvancedMode"
                class="px-2"
                cols="6">
                <CySearchBox
                  v-model.trim="advancedQuery"
                  :label="$t('fieldQuery')"
                  clearable
                  class="search-field"/>
              </v-col>

              <v-col
                v-show="!isAdvancedMode"
                class="px-2"
                cols="3">
                <CySearchBox
                  v-model.trim="host"
                  :label="$t('fieldHost')"
                  clearable
                  class="search-field"/>
              </v-col>

              <v-col
                v-show="!isAdvancedMode"
                cols="3"
                class="px-2">
                <CySearchBox
                  v-model.trim="message"
                  :label="$t('fieldMessage')"
                  clearable
                  class="search-field"/>
              </v-col>

              <v-col
                cols="6"
                class="px-2">
                <CyInputsDateTimeRange
                  ref="dateTimeRange"
                  :relative-options="$static.dateTimeRangeOptions"
                  :value="_.mapValues(dateRange, Number)"
                  activator-label-format="MMM DD, YYYY HH:mm"
                  mandatory
                  future-dates-disabled
                  @change="updateDateRange">
                  <template #activator="{ on, label }">
                    <div v-on="on">
                      <v-select
                        :value="label"
                        :items="[label]"
                        :label="$t('forms.field.timeRange')"
                        class="required-field"
                        hide-details
                        readonly/>
                    </div>
                  </template>
                </CyInputsDateTimeRange>
              </v-col>
            </v-row>

            <v-row
              no-gutters
              class="advanced-mode align-center mb-5">
              <v-checkbox
                v-model="isAdvancedMode"
                :label="$t('fieldAdvancedMode')"
                class="shrink mt-0 mode-checkbox"
                hide-details/>
              <button
                class="advanced-mode__hint-btn cy-icon-btn--small cy-icon-btn--info mt-1"
                @click="$toggle.showExamples">
                <v-icon class="cy-icon-btn__icon">
                  fa-question
                </v-icon>
              </button>
            </v-row>
            <transition name="expand">
              <div v-if="showExamples && !isFullScreen">
                <CyCodeSyntaxExamples
                  :log-provider="logProvider.configuration.engine || 'AWSCloudWatchLogs'"
                  @close-examples="$toggle.showExamples"/>
              </div>
            </transition>
          </div>

          <div
            v-if="isLoadingEntries"
            class="mt-4 text-center">
            <v-progress-circular
              indeterminate
              color="secondary"/>
          </div>

          <div v-if="!isLoadingEntries && hasSelection">
            <div class="logs-container">
              <CyTooltip
                left
                class="logs-container__action-btn action-btn">
                <template #activator="{ on }">
                  <span
                    class="logs-container__action-btn-wrapper"
                    v-on="on">
                    <CyButton
                      :key="expandIcon"
                      :icon="expandIcon"
                      theme="grey"
                      icon-only
                      @click="$toggle.isFullScreen"/>
                  </span>
                </template>
                {{ isFullScreen ? $t('collapse') : $t('expand') }}
              </CyTooltip>
              <v-data-iterator
                v-if="!_.isEmpty(logSources)"
                :items="logEntries"
                :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
                :options.sync="options"
                hide-default-footer
                @update:options="backToTop">
                <template
                  v-if="!_.isEmpty(logEntriesErrors)"
                  #no-data>
                  <div
                    v-for="(error, index) in logEntriesErrors"
                    :key="index"
                    class="no-data logs-body py-4 px-6">
                    <p>{{ error.message }}</p>
                    <ul
                      v-if="!_.isEmpty(error.details)"
                      class="mb-3">
                      <li
                        v-for="(detail, idx) of error.details"
                        :key="idx">
                        {{ detail }}
                      </li>
                    </ul>
                  </div>
                </template>
                <template
                  v-else
                  #no-data>
                  <div class="no-data logs-body py-4 px-6">
                    <h4 class="no-logs">
                      {{ $t('noDataTitle') }}
                    </h4>
                    <p class="no-logs-desc">
                      {{ $t('noDataDesc') }}
                    </p>
                  </div>
                </template>
                <template #header>
                  <CyDataTablePagination
                    v-if="!isFullScreen && logEntries.length"
                    :items-length="logEntries.length"
                    :options.sync="options"
                    is-header/>
                </template>
                <template
                  #default="{ items }">
                  <div class="logs-body py-4 px-6">
                    <v-row
                      v-for="(item, idx) in items"
                      :key="`${item}:${idx}`"
                      no-gutters
                      class="py-1">
                      <v-col
                        :style="{ color: getHostColor(item.host) }"
                        class="host-name pr-2"
                        cols="5"
                        sm="4"
                        md="3">
                        {{ item.host }}
                      </v-col>
                      <v-col
                        cols="7"
                        sm="8"
                        md="9">
                        <pre
                          class="log-entry"
                          v-html="getFormattedEntryMsg($sanitizeHtml(item.message))"/>
                      </v-col>
                    </v-row>
                  </div>
                </template>
                <template #footer>
                  <CyDataTablePagination
                    v-if="logEntries.length"
                    :items-length="logEntries.length"
                    :options.sync="options"
                    :items-per-page-options="[50, 100, 200]"/>
                </template>
              </v-data-iterator>
            </div>
          </div>

          <div
            v-if="!hasEnvSources"
            class="logs-container">
            <div class="logs-body py-4 pr-2 text-center">
              <div
                slot="no-data"
                class="no-data">
                {{ $t('noSources') }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import CyCodeSyntaxExamples from '@/components/code-syntax-examples.vue'
import CyDataTablePagination from '@/components/data-table/pagination.vue'
import CyInputsDateTimeRange from '@/components/inputs/date-time-range.vue'
import CySearchBox from '@/components/search-box.vue'
import { constructBreadcrumb } from '@/utils/helpers'
import distinctColors from 'distinct-colors'

export default {
  name: 'CyPageLogs',
  components: {
    CyInputsDateTimeRange,
    CyCodeSyntaxExamples,
    CySearchBox,
    CyDataTablePagination,
  },
  breadcrumb () {
    const { projectCanonical, projectName, envCanonical } = this
    return constructBreadcrumb(this.$options.name, envCanonical, [
      {
        label: this.$t('routes.projectEnvironments'),
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical, envCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    advancedQuery: '',
    host: '',
    message: '',
    selection: {
      source: null,
    },
    dateRange: {
      begin: $date.sub(Date.now(), { days: 1 }),
      end: Date.now(),
    },
    itemsPerPageOptions: [50, 100, 200],
    options: {
      itemsPerPage: 50,
      page: 1,
    },
    lockDataIteratorOptionsEvent: false,
    hostColors: {},
    isFullScreen: false,
    isLoading: false,
    isLoadingEntries: false,
    showExamples: false,
  }),
  computed: {
    $static () {
      return {
        dateTimeRangeOptions: [
          {
            label: this.$tc('forms.field.timeRangeLastMinutes', 15, { minutes: 15 }),
            value: '15m',
          },
          {
            label: this.$tc('forms.field.timeRangeLastHours', 2, { hours: 2 }),
            value: '2H',
          },
          {
            label: this.$tc('forms.field.timeRangeLastHours', 12, { hours: 12 }),
            value: '12H',
          },
          {
            label: this.$tc('forms.field.timeRangeLastDays', 1),
            value: '1d',
          },
          {
            label: this.$tc('forms.field.timeRangeLastDays', 3, { days: 3 }),
            value: '3d',
          },
          {
            label: this.$t('forms.field.timeRangeLastWeek'),
            value: '1w',
          },
        ],
      }
    },
    ...mapState('organization/project', {
      selected: (state) => state.selected,
    }),
    ...mapGetters('organization/project', [
      'logProvider',
    ]),
    ...mapState('organization/project', {
      logEntries: (state) => state.logEntries,
      logEntriesErrors: (state) => state.errors.logEntries,
      logSources: (state) => state.logSources,
    }),
    expandIcon () {
      return this.isFullScreen ? 'fa-compress' : 'fa-expand'
    },
    hasEnvSources () {
      return !_.isEmpty(this.logSources)
    },
    hasSelection () {
      const { source } = this.selection
      return !_.isEmpty(source)
    },
    isAdvancedMode: {
      get () {
        return JSON.parse(_.$get(this.$route.query, 'isAdvancedMode', 'false'))
      },
      set (isAdvancedMode) {
        this.$router.replace({ query: { ...this.$route.query, isAdvancedMode } }).catch(() => { /* silenced */ })
      },
    },
    queryFields () {
      return `${this.advancedQuery}${this.host}${this.message}`
    },
  },
  watch: {
    async queryFields () {
      await this.fetchLogEntries()
      this.resetPagination()
    },
    options: {
      handler (options, oldOptions) {
        if (!this.lockDataIteratorOptionsEvent) this.handleRedirect()
      },
      deep: true,
    },
    envCanonical: {
      async handler (env) {
        this.SELECT_ENV(env)
        this.isLoading = true
        this.$resetData('selection')
        await this.getEnvLogSources()
        if (this.hasEnvSources) this.setSource()
        this.isLoading = false
      },
    },
  },
  async mounted () {
    this.isLoading = true

    this.setDataFromQuery()
    this.lockDataIteratorOptionsEvent = this.options.page !== 1
    await this.getEnvLogSources()
    if (this.hasEnvSources) this.setSource()
    await this.GET_EXTERNAL_BACKENDS()
    this.GET_PROJECT_PIPELINES()

    this.isLoading = false
  },
  methods: {
    ...mapActions('organization/project', [
      'GET_EXTERNAL_BACKENDS',
      'GET_LOG_ENTRIES',
      'GET_LOG_SOURCES',
      'GET_PROJECT_PIPELINES',
    ]),
    ...mapMutations('organization/project', [
      'SELECT_ENV',
    ]),
    backToTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    getFormattedEntryMsg (msg) {
      if (this.message) {
        const regex = new RegExp(this.message, 'gi')
        msg = msg.replace(regex, (match) => `<span class="highlight">${match}</span>`)
      }
      return msg
    },
    generateHostsColors (hosts) {
      const uniqueHosts = _.uniq(hosts).sort()
      const colors = distinctColors({
        lightMin: 70,
      })
      uniqueHosts.forEach((host, index) => {
        this.$set(this.hostColors, host, colors[index])
      })
    },
    getHostColor (host) {
      return this.hostColors[host] || null
    },
    setSource () {
      const { selection, logSources } = this
      const source = !_.isEmpty(selection.source) ? selection.source : logSources[0].id
      this.selectSource(source, true)
    },
    selectSource (source, atPageLoad = false) {
      const { selected } = this
      this.$set(this, 'selection', _.cloneDeep({ source }))

      if (!atPageLoad) this.options.page = 1
      if (selected.env && source && this.dateRange.begin) this.fetchLogEntries()
    },
    async getEnvLogSources () {
      const { envCanonical, selected: { env } } = this

      if (_.isEmpty(this.envCanonical)) {
        await this.GET_LOG_SOURCES(env)
        return
      }
      await this.GET_LOG_SOURCES(envCanonical)
    },
    generateSimpleQuery () {
      if (_.every([this.message, this.host], _.isEmpty)) return ''

      const message = this.message ? `$.message = "*${this.message}*"` : ''
      const host = this.host ? `$.hostname = "*${this.host}*"` : ''
      const operatorAnd = message && host ? ' && ' : ''
      return `{${message}${operatorAnd}${host}}`
    },
    async fetchLogEntries () {
      const {
        selected,
        selection: { source },
        dateRange: { begin, end },
      } = this

      if (!this.hasSelection) return

      this.isLoadingEntries = true
      this.entriesInvalid = false
      this.handleRedirect()

      const query = this.isAdvancedMode
        ? this.advancedQuery
        : this.generateSimpleQuery()

      await this.GET_LOG_ENTRIES({ envCanonical: selected.env, source, begin, end, query })
      if (!_.isEmpty(this.logEntries)) this.generateHostsColors(_.map(this.logEntries, 'host'))
      this.isLoadingEntries = false
    },
    handleRedirect () {
      const {
        selection: { source },
        selected: { env },
        dateRange: { begin, end },
        options: { page, itemsPerPage },
        isAdvancedMode, host, message, advancedQuery,
      } = this
      const query = isAdvancedMode
        ? _.omitBy({ isAdvancedMode, begin, end, source, env, page, itemsPerPage, advancedQuery }, _.$isEmpty)
        : _.omitBy({ isAdvancedMode, begin, end, source, env, page, itemsPerPage, host, message }, _.$isEmpty)
      if (!_.isEqual(query, this.$route.query)) {
        this.$router.replace({ query }).catch(() => { /* silenced */ })
      }
    },
    updateDateRange ({ value: { begin, end } } = {}) {
      if (begin) this.$set(this.dateRange, 'begin', begin)
      if (end) this.$set(this.dateRange, 'end', end)
      this.resetPagination()
      this.fetchLogEntries()
    },
    setDataFromQuery () {
      const { itemsPerPage, page, host, message, advancedQuery, begin, end, env, source } = this.$route.query

      if (itemsPerPage) this.$set(this.options, 'itemsPerPage', Number(itemsPerPage))
      if (page) this.$set(this.options, 'page', Number(page))
      if (begin) this.$set(this.dateRange, 'begin', begin)
      if (end) this.$set(this.dateRange, 'end', end)
      if (env) this.SELECT_ENV(env)
      else this.SELECT_ENV(this.envCanonical)
      if (source) this.$set(this.selection, 'source', source)
      if (host) this.$set(this, 'host', host)
      if (message) this.$set(this, 'message', message)
      if (advancedQuery) this.$set(this, 'advancedQuery', advancedQuery)
    },
    resetPagination () {
      this.options.page = 1
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.logs',
        btnConfig: 'Define a new backend for logs',
        expand: 'expand',
        fieldAdvancedMode: 'Advanced mode',
        fieldHost: 'Host',
        fieldMessage: 'Message',
        fieldQuery: 'Type your query',
        noConfigDesc: 'To show logs for this project, you need to configure the logs backend that you will use.',
        noConfigTitle: 'No logs configuration',
        noDataDesc: 'Try another time range or a different query.',
        noDataTitle: 'No logs found.',
        noSource: 'No source was found',
        noSources: 'No source was found in your environment',
        sources: 'Sources',
      },
      es: {
        title: '@:routes.logs',
        btnConfig: 'Definir un nuevo backend para los Logs',
        expand: 'expandir',
        fieldAdvancedMode: 'Modo avanzado',
        fieldHost: 'Anfitrión',
        fieldMessage: 'Mensaje',
        fieldQuery: 'Escriba su consulta',
        noConfigDesc: 'Para mostrar los Logs de este proyecto, debes configurar el backend de logs que vas utilizar.',
        noConfigTitle: 'Sin configuración de los Logs',
        noDataDesc: 'Pruebe con otro rango de tiempo u otro query.',
        noDataTitle: 'Ningún log encontrado.',
        noSource: 'No se encontraron fuentes',
        noSources: 'No se encontró ninguna fuente en su entorno',
        sources: 'Fuentes',
      },
      fr: {
        title: '@:routes.logs',
        btnConfig: 'Définissez un nouveau backend pour les logs',
        closeCustomPeriodTooltip: 'Fermer la sélection de période personnalisée',
        expand: 'étendre',
        fieldAdvancedMode: 'Mode avancé',
        fieldHost: 'Hôte',
        fieldMessage: 'Message',
        fieldQuery: 'Tapez votre requête',
        noConfigDesc: 'Pour afficher les Logs pour ce projet, vous devez configurer le backend de Logs que vous utiliserez.',
        noConfigTitle: 'Pas de configuration des logs',
        noDataDesc: 'Essayez une autre intervalle de temps ou une requête différente.',
        noDataTitle: 'Aucun log trouvé.',
        noSource: `Aucune source n'a été trouvée`,
        noSources: `Aucune source n'a été trouvée dans votre environnement`,
        sources: 'Sources',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$color-highlight: #f4ff81;

::v-deep {
  .v-expansion-panel {
    .new-header & {
      background-color: transparent;
      box-shadow: none;
    }
  }

  .v-list {
    .new-header & {
      background: transparent;
    }
  }
}

::v-deep .v-card {
  .new-header & {
    background-color: transparent;

    &:not(.v-sheet--outlined) {
      box-shadow: none;
    }
  }
}

.advanced-mode {
  height: inherit !important;

  &__hint-btn {
    margin-left: 1em;
  }
}

.no-logs {
  color: cy-get-color("white");
}

.no-logs-desc {
  color: cy-get-color("primary", "light-3");
}

.env-title {
  color: cy-get-color("primary");
  font-size: 15px;
  font-weight: bold;
}

.font-16 {
  font-size: 16px;
}

h3 {
  color: cy-get-color("primary");
}

.host-name {
  font-weight: bolder;
  word-wrap: break-word;
}

.log-entry {
  word-break: break-all;
  white-space: inherit;

  ::v-deep .highlight {
    background-color: $color-highlight;
    color: cy-get-color("black");
  }
}

.logs-container {
  position: relative;
  z-index: 5;

  ::v-deep .logs-body {
    min-height: 400px;
    border-radius: 4px;
    background-color: cy-get-color("black");
    color: cy-get-color("white");
    font-family: $font-family-code;
  }

  &__action-btn-wrapper {
    position: absolute;
    z-index: 10;
    top: 1em;
    right: 1.5em;
  }

  .no-data {
    padding: 50px;
    color: cy-get-color("white");
    font-family: $font-family-code;
  }
}

.mode-checkbox {
  font-size: 14px;

  ::v-deep {
    .cursor-pointer {
      cursor: pointer;
    }

    .v-messages {
      min-height: 0;
    }
  }
}

.source-selection {
  ::v-deep {
    .highlight {
      background-color: $color-highlight;
      color: cy-get-color("black");
    }

    .source-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .v-list {
      .v-subheader {
        height: 36px;
        padding: 0;
        font-size: 12px;
        font-weight: $font-weight-bolder;
        letter-spacing: 0.05em;
        text-transform: uppercase;
      }

      .v-list-item {
        min-height: 24px;
        padding-left: 8px;

        &__title {
          display: flex;
          justify-content: flex-start;
          color: cy-get-color("primary");
          line-height: 1.5;
        }

        &__content {
          padding: 8px 16px;
        }
      }
    }
  }
}
</style>
